import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TipoContract } from '@usucampeao/lib-contratos';
import { CadastroContratoDto, CadastroDocumentoTipo, FileDto } from '@usucampeao/lib-reurb-simplificado';

import { ModalContratoProcuracaoComponent } from '../../../components';
import { Arquivo, RemoverArquivoOutput } from '../../../components/upload-foto/upload-foto.component';
import { DocumentoService, NavigationService } from '../../../services';
import { ToastService } from '../../../shared';
import { ArquivosEnviadosRemovidos } from './arquivos-enviados.interface';

@Component({
  selector: 'usucampeao-documentos-upload',
  templateUrl: './documentos-upload.component.html',
  styleUrls: ['./documentos-upload.component.scss']
})
export class DocumentosUploadComponent implements OnInit, OnChanges, OnDestroy {
  @Input() documentoId: string;
  @Input() tipo: string;
  @Input() arquivos: FileDto[] = [];
  @Input() dadosContrato: CadastroContratoDto;

  @Output() aoRealizarUpload = new EventEmitter<ArquivosEnviadosRemovidos>();

  public arquivosASeremEnviados: File[] = [];
  public idsArquivosRemovidos: string[] = [];
  public arquivosDocumento: Arquivo[] = [];
  private documentoVazio = true;

  constructor(
    private readonly documentoService: DocumentoService,
    private readonly navigationService: NavigationService,
    private readonly modalCtrl: ModalController,
    private readonly toastService: ToastService
  ) { }

  ngOnInit(): void {
    const arquivoSalvoStorage = this.documentoService.obterDocumentoStorage();
    if (arquivoSalvoStorage) {
      this.documentoVazio = false;
      this.arquivosDocumento.push(arquivoSalvoStorage);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.arquivos) {
      this.configurarArquivosDocumento();
    }
  }

  ngOnDestroy(): void {
    this.documentoService.removerDocumentoStorage();
  }

  public get isProcuracao(): boolean {
    return this.tipo === CadastroDocumentoTipo.PROCURACAO;
  }

  public get isContrato(): boolean {
    return this.tipo === CadastroDocumentoTipo.CONTRATO;
  }

  public get textoBotaoSalvar(): string {
    return this.documentoId ? 'Salvar documento' : 'Adicionar documento';
  }

  public get botaoSalvarDesativado(): boolean {
    return (this.arquivosASeremEnviados.length === 0 && this.idsArquivosRemovidos.length === 0) || this.documentoVazio;
  }

  private configurarArquivosDocumento(): void {
    if (!this.arquivos) {
      this.arquivosDocumento = [];
    } else {
      this.arquivosDocumento = this.arquivos.map(arquivo => ({
        id: arquivo.id,
        arquivoPdf: arquivo.mimeType.includes('pdf'),
        base64: `/documents/${this.documentoId}/files/${arquivo.id}/download`
      }));
    }
  }

  public adicionarArquivo(arquivos: File[]): void {
    if (arquivos.length) {
      this.documentoVazio = false;
    }

    this.arquivosASeremEnviados = arquivos;
  }

  public removerArquivo(data: RemoverArquivoOutput): void {
    this.idsArquivosRemovidos = data.ids;
    this.documentoVazio = data.semArquivosNoDocumento;
  }

  public async realizarUpload(): Promise<void> {
    this.aoRealizarUpload.emit({ arquivosEnviados: this.arquivosASeremEnviados, idsArquivosRemovidos: this.idsArquivosRemovidos });
  }

  public voltar(): void {
    this.navigationService.voltar();
  }

  public async abrirModalProcuracao(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalContratoProcuracaoComponent,
      componentProps: {
        tipo: this.dadosContrato.averbacao ? TipoContract.PROCURACAO_REURB_AVERBACAO : TipoContract.PROCURACAO_AVERBACAO,
        dadosContrato: this.dadosContrato
      },
    });
    await modal.present();
  }
}
