
export enum BaixaManualFormaPagamento {
  ACAO_PROMOCIONAL = 'ACAO_PROMOCIONAL',
  BOLETO_SANTANDER = 'BOLETO_SANTANDER',
  BOLETO_BS2 = 'BOLETO_BS2',
  CARTAO_CREDITO_MAQUINA = 'CARTAO_CREDITO_MAQUINA',
  CARTAO_DEBITO_MAQUINA = 'CARTAO_DEBITO_MAQUINA',
  DEPOSITO = 'DEPOSITO',
  DINHEIRO = 'DINHEIRO',
  PIX = 'PIX',
};

export enum BaixaManualStatus {
  PENDENTE = 'PENDENTE',
  APROVADO = 'APROVADO',
  REPROVADO = 'REPROVADO',
  CANCELADO = 'CANCELADO',
};

export type CriarBaixaManualFormDataDto = {
  formaPagamento: BaixaManualFormaPagamento;
  dataPagamento: Date;
  valorPago: number;
  justificativa: string;
};
