import { CadastroContratoAssinaturaTipoEnvioLink, CadastroContratoTipoAssinatura } from '@usucampeao/interfaces';
import { Expose, Type } from 'class-transformer';
import { ReurbModalidade, StatusPreenchimento } from '../../enums';
import { AddressDto } from '../address/address.dto';
import { CupomDescontoTipoDesconto } from '../cupom-desconto';
import { FileDto } from '../file';
import { DiaPagamento, MeioPagamento } from '../pagamento';
import { CadastroCancelamentoMotivo } from './cadastro-cancelar.dto';
import { CadastroStatusVendaType } from './cadastro-status-venda.enum';
import { ContratoVersao } from './contrato-versao.enum';
import { ListRegistration } from './list-registration.dto';
import { PendingSections } from './pending-section.model';
import { RegistrationStatus } from './registration-status.enum';

export interface CadastroStatusPreenchimento {
  contrato: StatusPreenchimento;
  procuracao: StatusPreenchimento;
}

export class RegistrationSimplifiedDto extends ListRegistration {
  @Expose()
  blockId: number;

  @Expose()
  lotId: number;

  @Expose()
  fillInStatus: PendingSections;

  @Type(() => Date)
  @Expose()
  schedulingDate?: Date;

  @Expose()
  valorServico: number;

  @Expose()
  valorContrato: number;

  @Expose()
  ownerName: string;

  @Expose()
  totalParcelas: number;

  @Expose()
  valorParcela: number;

  @Expose()
  meioPagamento: MeioPagamento;

  @Expose()
  valorEntrada: number;

  @Expose()
  diaVencimento: DiaPagamento;

  @Expose()
  diaEntrada: DiaPagamento;

  @Expose()
  juros?: number;

  @Expose()
  jurosMes?: number;

  @Expose()
  multa?: number;

  @Expose()
  averbacao: boolean;

  @Expose()
  diarioOficialLink?: string;

  @Expose()
  prefeituraProtocolo?: string;

  @Expose()
  cartorioProtocolo?: string;

  @Type(() => Date)
  @Expose()
  entregaDataPrevista?: Date;

  @Expose()
  entregaLocal?: string;

  @Type(() => Date)
  @Expose()
  entregaData?: Date;

  @Expose()
  distratoData?: Date;

  @Expose()
  distratoMotivo?: CadastroCancelamentoMotivo;

  @Expose()
  distratoMotivoOutro?: string;

  @Expose()
  distratoObservacoes?: string;

  @Expose()
  distratoMulta?: number;

  @Expose()
  distratoReembolso?: number;

  @Expose()
  cancelamentoData?: Date;

  @Expose()
  cancelamentoMotivo?: CadastroCancelamentoMotivo;

  @Expose()
  cancelamentoObservacoes?: string;

  @Expose()
  cancelamentoMotivoOutro?: string;

  @Expose()
  contratoVersao: ContratoVersao;

  @Expose()
  statusPreenchimento: CadastroStatusPreenchimento;

  @Expose()
  dataCriacao: Date;

  @Expose()
  dataAtualizacao: Date;

  @Expose()
  observacao?: string;

  @Expose()
  usuarioCriacaoId?: string;

  @Expose()
  usuarioCriacaoNome?: string;

  @Expose()
  usuarioFidResponsavel?: string;

  @Expose()
  usuarioResponsavelNome?: string;

  @Expose()
  cupomDescontoCodigo?: string;

  @Expose()
  cupomDescontoTipo?: CupomDescontoTipoDesconto;

  @Expose()
  cupomDescontoLimite?: number;

  @Expose()
  contratoLinkContatoEnviado?: string;

  @Expose()
  contratoLinkFalhaEnvio?: boolean;

  @Expose()
  contratoLinkDataUltimoEnvio?: Date;

  @Expose()
  contratoLinkTipoEnvio?: CadastroContratoAssinaturaTipoEnvioLink;

  @Expose()
  proprietarioEmail: string;

  @Expose()
  proprietarioWhatsapp: string;

  @Expose()
  contratoLinkAssinadoPeloProprietario: boolean;

  @Expose()
  contratoLinkAssinadoPelaUsucampeao: boolean;

  constructor(
    id: string,
    status: RegistrationStatus,
    statusVenda: CadastroStatusVendaType,
    projetoFid: string,
    projetoNome: string,
    address: AddressDto,
    propertyId: string,
    files: FileDto[],
    blockId: number,
    quadra: string,
    lotId: number,
    lote: string,
    fillInStatus: PendingSections,
    reurbModalidade: ReurbModalidade,
    statusPreenchimento: CadastroStatusPreenchimento,
    dataCriacao: Date,
    dataAtualizacao: Date,
    proprietarioId: string,
    proprietarioPrincipalNome: string,
    proprietarioPrincipalEmail: string,
    proprietarioPrincipalCelular: string,
    contratoVersao?: ContratoVersao,
    contractDate?: Date,
    contratoDataExpiracao?: Date,
    contratoDataAssinatura?: Date,
    contratoTipoAssinatura?: CadastroContratoTipoAssinatura,
    contratoLinkDataUltimoEnvio?: Date,
    contratoLinkContatoEnviado?: string,
    contratoLinkFalhaEnvio?: boolean,
    contratoLinkTipoEnvio?: CadastroContratoAssinaturaTipoEnvioLink,
    contratoLinkAssinadoPelaUsucampeao?: boolean,
    contratoLinkAssinadoPeloProprietario?: boolean,
    schedulingDate?: Date,
    valorServico?: number,
    valorContrato?: number,
    totalParcelas?: number,
    valorParcela?: number,
    meioPagamento?: MeioPagamento,
    valorEntrada?: number,
    juros?: number,
    jurosMes?: number,
    multa?: number,
    diaVencimento?: DiaPagamento,
    diaEntrada?: DiaPagamento,
    averbacao?: boolean,
    diarioOficialLink?: string,
    prefeituraProtocolo?: string,
    cartorioProtocolo?: string,
    entregaDataPrevista?: Date,
    entregaLocal?: string,
    entregaData?: Date,
    observacao?: string,
    usuarioCriacaoId?: string,
    usuarioCriacaoNome?: string,
    usuarioResponsavelFid?: string,
    usuarioResponsavelNome?: string,
    distratoData?: Date,
    distratoMotivo?: CadastroCancelamentoMotivo,
    distratoObservacoes?: string,
    distratoMotivoOutro?: string,
    distratoMulta?: number,
    distratoReembolso?: number,
    cancelamentoData?: Date,
    cancelamentoMotivo?: CadastroCancelamentoMotivo,
    cancelamentoObservacoes?: string,
    cancelamentoMotivoOutro?: string,
    cupomDescontoCodigo?: string,
    cupomDescontoTipo?: CupomDescontoTipoDesconto,
    cupomDescontoLimite?: number,
  ) {
    super(
      id,
      status,
      statusVenda,
      projetoFid,
      projetoNome,
      address,
      propertyId,
      quadra,
      lote,
      files,
      reurbModalidade,
      contractDate,
      contratoDataAssinatura,
      contratoTipoAssinatura,
      contratoDataExpiracao,
      proprietarioId
    );
    this.blockId = blockId;
    this.lotId = lotId;
    this.fillInStatus = fillInStatus;
    this.schedulingDate = schedulingDate;
    this.valorServico = valorServico;
    this.valorContrato = valorContrato;
    this.totalParcelas = totalParcelas;
    this.valorParcela = valorParcela;
    this.meioPagamento = meioPagamento;
    this.valorEntrada = valorEntrada;
    this.diaVencimento = diaVencimento;
    this.juros = juros;
    this.jurosMes = jurosMes;
    this.multa = multa;
    this.diaEntrada = diaEntrada;
    this.statusPreenchimento = statusPreenchimento;
    this.averbacao = averbacao;
    this.diarioOficialLink = diarioOficialLink;
    this.prefeituraProtocolo = prefeituraProtocolo;
    this.cartorioProtocolo = cartorioProtocolo;
    this.entregaDataPrevista = entregaDataPrevista;
    this.entregaLocal = entregaLocal;
    this.entregaData = entregaData;
    this.contratoVersao = contratoVersao || ContratoVersao.V1;
    this.dataCriacao = dataCriacao;
    this.dataAtualizacao = dataAtualizacao;
    this.observacao = observacao;
    this.usuarioCriacaoId = usuarioCriacaoId;
    this.usuarioCriacaoNome = usuarioCriacaoNome;
    this.usuarioFidResponsavel = usuarioResponsavelFid;
    this.usuarioResponsavelNome = usuarioResponsavelNome;
    this.distratoData = distratoData;
    this.distratoMotivo = distratoMotivo;
    this.distratoObservacoes = distratoObservacoes;
    this.distratoMotivoOutro = distratoMotivoOutro;
    this.distratoMulta = distratoMulta;
    this.distratoReembolso = distratoReembolso;
    this.cancelamentoData = cancelamentoData;
    this.cancelamentoMotivo = cancelamentoMotivo;
    this.cancelamentoObservacoes = cancelamentoObservacoes;
    this.cancelamentoMotivoOutro = cancelamentoMotivoOutro;
    this.ownerName = proprietarioPrincipalNome;
    this.proprietarioEmail = proprietarioPrincipalEmail;
    this.proprietarioWhatsapp = proprietarioPrincipalCelular;
    this.cupomDescontoCodigo = cupomDescontoCodigo;
    this.cupomDescontoTipo = cupomDescontoTipo;
    this.cupomDescontoLimite = cupomDescontoLimite;
    this.contratoLinkDataUltimoEnvio = contratoLinkDataUltimoEnvio;
    this.contratoLinkContatoEnviado = contratoLinkContatoEnviado;
    this.contratoLinkFalhaEnvio = contratoLinkFalhaEnvio;
    this.contratoLinkTipoEnvio = contratoLinkTipoEnvio;
    this.contratoLinkAssinadoPelaUsucampeao = contratoLinkAssinadoPelaUsucampeao ?? false;
    this.contratoLinkAssinadoPeloProprietario = contratoLinkAssinadoPeloProprietario ?? false;
  }
}
