import { GatewayPagamento, MeioPagamento, PagamentoStatus } from '.';

export class PagamentoDto {
  id: string;
  cadastroId: string;
  status: PagamentoStatus;
  parcela: number;
  totalParcelas: number;
  descricao?: string;
  meioPagamento: MeioPagamento;
  valorPago?: number;
  dataPagamento?: Date;
  valorDevido: number;
  dataVencimento: Date;
  codigoDeBarras?: string;
  linhaDigitavel?: string;
  boletoPdf?: string;
  downloadUrl?: string;
  integracaoId?: string;
  integracaoNumero?: number;
  gatewayPagamento?: GatewayPagamento;
  justificativaCancelamento?: string;
  usuarioCancelamentoId?: string;
  dataSolicitacaoCancelamento?: Date;
  dataCancelamentoEfetuado?: Date;
  dataEmissao?: Date;
  dataCriacao?: Date;
  dataAtualizacao?: Date;
  proprietarioId: string;
  imovelId: string;
  juros: number;
  valorAmortizado: number;
  saldoDevedor: number;
  usuarioBaixaId?: number;
  usuarioBaixaFid?: string;
  log?: string;

  constructor(pagamento?: Partial<PagamentoDto>) {
    if (pagamento) {
      Object.keys(pagamento).forEach(key => this[key] = pagamento[key]);
    }
  }
}

export class PagamentoCancelarTopicDto {
  cadastroId: string;
  pagamentoId: string;
  justificativa?: string;
}
