export type RenegociacaoInput = {
  pagamentosIdsARenegociar: string[];
  aplicarEncargos: boolean;
  dataPrimeiroPagamento: Date;
  valorPrimeiroPagamento: number;
  quantidadeParcelas: number;
};

export type RenegociacaoParcela = {
  parcela: number;
  dataVencimento: Date;
  valorDevido: number;
  valorAmortizado: number;
  saldoDevedor: number;
  valorJuros: number;
};

export type RenegociacaoSimularOutput = {
  parcelas: RenegociacaoParcela[];
  valorTotalRenegociacao: number;
  valorMedioParcela: number;
  totalParcelasContratoRenegociado: number;
  totalParcelasContratoOriginal: number;
  valorContratoOriginal: number;
  valorContratoRenegociado: number;
};

export enum RenegociacaoStatus {
  PENDENTE = 'PENDENTE',
  APROVADO = 'APROVADO',
  REPROVADO = 'REPROVADO',
};

export type RenegociacaoListarDto = {
  id: string;
  cadastroId: string;
  status: RenegociacaoStatus;
  usuarioCriacaoId: string;
  usuarioCriacao: string;
  dataCriacao: Date;
  dataAtualizacao: Date;
  usuarioAprovador: string | null;
  valorContratoOriginal: number;
  valorContratoRenegociado: number;
};

export type Renegociacao = {
  id: string;
  status: RenegociacaoStatus;
  usuarioAprovadorId: string | null;
  dataCriacao: Date;
  dataAtualizacao: Date;
  valorContratoOriginal: number;
  valorContratoRenegociado: number;
  valorParcelaContratoOriginal: number;
  totalParcelasContratoOriginal: number;
  totalParcelasContratoRenegociado: number;
  possuiEncargos: boolean;
  dataPrimeiroPagamento: Date;
  quantidadeParcelas: number;
  valorTotal: number;
  valorParcela: number;
  valorParcelaEntrada: number;
  cadastroId: string;
  usuarioCriacaoId: string;
  pagamentosRenegociados: PagamentoRenegociado[];
};

export enum RenegociacaoPagamentoStatus {
  ADICIONADO = 'ADICIONADO',
  CANCELADO = 'CANCELADO',
};

export type PagamentoRenegociado = {
  numeroParcela: number;
  valorDevido: number;
  dataVencimento: Date;
  status: RenegociacaoPagamentoStatus;
  renegociado: boolean;
};
