import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { Camera, CameraResultType } from '@capacitor/camera';

export interface Arquivo {
  id?: string;
  base64: string;
  arquivoPdf: boolean;
  formatoArquivo?: string;
}

export type RemoverArquivoOutput = {
  ids: string[];
  semArquivosNoDocumento: boolean;
}


@Directive()
export abstract class UploadFotoComponent {
  @Input() readonly arquivoPdf = true;
  @Input() podeEnviarVariosArquivos = true;
  @Input() podeEnviarPDF = true;
  @Input() urlImagem: string;
  @Input() imagensBase64: Arquivo[] = [];
  @Output() aoRealizarUpload = new EventEmitter<File[]>();
  @Output() aoRealizarUploadBase64 = new EventEmitter<Arquivo[]>();
  @Output() aoRemoverArquivo = new EventEmitter<RemoverArquivoOutput>();

  protected imagensArquivos: File[] = [];
  protected uploadEstaDesabilitado = false;

  public indiceImagemSelecionada = 0;
  public imagensRemovidas: string[] = [];

  public get urlImagemSelecionada(): string {
    const file = this.imagensBase64[this.indiceImagemSelecionada]?.base64 || this.urlImagem;
    return file ? file : '';
  }

  public async selecionarImagem(): Promise<void> {
    if (this.uploadEstaDesabilitado) {
      return;
    }

    this.uploadEstaDesabilitado = true;
    try {
      const arquivoBase64 = await Camera.getPhoto({
        quality: 80,
        saveToGallery: false,
        allowEditing: true,
        resultType: CameraResultType.Base64,
      });

      let formatoArquivo: string;
      let arquivoPdf = false;
      if (arquivoBase64.format === 'pdf') {
        if (!this.podeEnviarPDF) {
          return;
        }
        formatoArquivo = 'application/pdf';
        arquivoPdf = true;
      } else {
        formatoArquivo = `image/${arquivoBase64.format}`;
        arquivoPdf = false;
      }

      this.gerarArquivoEEmitirUpload(arquivoBase64.base64String, formatoArquivo, arquivoPdf);
    } catch (error) {
      // não é necessário fazer nada, o usuário apenas cancelou a câmera
    }
    this.uploadEstaDesabilitado = false;
  }

  protected gerarArquivoEEmitirUpload(base64String: string, formatoArquivo: string, arquivoPdf: boolean): void {
    if (base64String.includes('data:')) {
      base64String = base64String.split(',')[1];
    }

    const arquivo = this.b64toBlob(base64String, formatoArquivo);
    this.urlImagem = `data:${formatoArquivo};base64,` + base64String;

    const arquivoEnviado: Arquivo = { base64: this.urlImagem, arquivoPdf, formatoArquivo };
    if (this.podeEnviarVariosArquivos) {
      this.imagensBase64.push(arquivoEnviado);
      this.imagensArquivos.push(arquivo);
      this.indiceImagemSelecionada = this.imagensBase64.length - 1;
    } else {
      this.imagensBase64 = [arquivoEnviado];
      this.imagensArquivos = [arquivo];
    }

    this.aoRealizarUpload.emit(this.imagensArquivos);
    this.aoRealizarUploadBase64.emit(this.imagensBase64);
  }

  public removerImagem(): void {
    const arquivoSelecionado = this.imagensBase64[this.indiceImagemSelecionada];
    if (!arquivoSelecionado) {
      return;
    }


    this.imagensArquivos.splice(this.indiceImagemSelecionada, 1);
    this.imagensBase64.splice(this.indiceImagemSelecionada, 1);

    if (arquivoSelecionado.id) {
      this.imagensRemovidas.push(arquivoSelecionado.id);
      this.aoRemoverArquivo.emit({ ids: this.imagensRemovidas, semArquivosNoDocumento: !this.imagensBase64.length });
    }

    const estaNaUltimaPagina = this.indiceImagemSelecionada === this.imagensBase64.length;
    if (estaNaUltimaPagina && this.indiceImagemSelecionada > 0) {
      this.indiceImagemSelecionada -= 1;
    }

    this.aoRealizarUpload.emit(this.imagensArquivos);
    this.aoRealizarUploadBase64.emit(this.imagensBase64);
    this.urlImagem = '';
  }

  private b64toBlob(b64Data: string, contentType = ''): File {
    const rawData = window.atob(b64Data);
    const bytes = new Array(rawData.length);
    for (let x = 0; x < rawData.length; x++) {
      bytes[x] = rawData.charCodeAt(x);
    }
    const arr = new Uint8Array(bytes);
    const blob = new Blob([arr], { type: contentType });

    return new File([blob], `${new Date().getTime()}`, { type: contentType });
  }

  public irParaProximaImagem(): void {
    if (this.imagensBase64[this.indiceImagemSelecionada] && this.podeEnviarVariosArquivos) {
      this.indiceImagemSelecionada += 1;
    }
  }

  public irParaImagemAnterior(): void {
    if (this.indiceImagemSelecionada > 0 && this.podeEnviarVariosArquivos) {
      this.indiceImagemSelecionada -= 1;
    }
  }

  protected resetar(): void {
    this.imagensBase64 = [];
    this.imagensArquivos = [];
    this.indiceImagemSelecionada = 0;
    this.urlImagem = '';
    this.imagensRemovidas = [];
  }

}
